import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Alert from '@mui/material/Alert';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import axios from "axios";
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Rating from '@mui/material/Rating';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import Tooltip from '@mui/material/Tooltip';
import InsightsIcon from '@mui/icons-material/Insights';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Divider from '@mui/material/Divider';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import TextField from '@mui/material/TextField';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import socketIO from 'socket.io-client';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const InterviewPage = (props) => {
  const params                                      = useParams();
  const navigate                                    = useNavigate();
  const [isError, setIsError]                       = useState('');
  const [loggedUser, setLoggedUser]                 = useState("");
  const [isInprogress, setInprogress]               = useState(true);
  // auth
  const [auth, setAuth]                             = useState(false);
  const [authLoading, setAuthLoading]               = useState(true);
  const [socket, setSocket]                         = useState(null);
  const [isSocketConnected, setIsSocketConnected]   = useState(false);

  // mobile viewport detection
  const [width, setWidth] = useState(window.innerWidth);

  // page data
  const [interviewMetadata, setInterviewMetadata]   = useState({});
  const [interviewData, setInterviewData]           = useState([]);
  const [hrsLeftInterview, setHrsLeftInterview]     = useState(false);
  const [isExpirationInterview, setIsExpirationInterview] = useState(false);
  const [isExpiredInterview, setIsExpiredInterview] = useState(false);
  const [videoPlayerModalIndex, setVideoPlayerModalIndex] = useState(null);
  const [isRatingEnabled, setIsRatingEnabled]       = useState(false);
  const [isAICheckOpen, setIsAICheckOpen]           = useState(false);
  const [isInprogressM, setInprogressM]             = useState(true);
  const [isInprogressN, setInprogressN]             = useState(false);
  const [isInprogressMLabel, setInprogressMLabel]   = useState(false);
  const [AIAnalysisContent, setAIAnalysisContent]   = useState(null);
  const [isEventViewOpen, setIsEventViewOpen]       = useState(false);
  const [isReschedViewOpen, setIsReschedViewOpen]   = useState(false);
  const [reschedDate, setReschedDate]               = useState(dayjs());

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);
  
  const isMobile = width <= 768;
  console.log(`Mobile Veiwport: ${isMobile}`);

  const removeCookie = (name, path = '/') => {
    var domain = process.env.REACT_APP_ROOT_DOMAIN;
    const cookieString = `${encodeURIComponent(name)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; Secure=; path=${path}`;
    if (domain) {
        document.cookie = `${cookieString}; domain=${domain}`;
    } else {
        document.cookie = cookieString;
    }
  };

  const tokenCleanup = (token) => {
    token = (token.startsWith('Ġ')) ? token.replace('Ġ', " ") : ((token.startsWith('Ċ') || token.startsWith('â')) ? " " : token);
    // token = (token.startsWith('Ġ')) ? token.slice(1) : ((token.startsWith('Ċ') || token.startsWith('â')) ? " " : token);
    // token = (token.startsWith('â')) ? '–' : token;
    // token = (token.startsWith('ľ')) ? '“' : token;
    // token = (token.startsWith('Ŀ')) ? '”' : token;
    // token = (token.startsWith('Ļ')) ? "'" : token;
    return token
  };

  const colorizedToken = (rank) =>{
    // '#ADFF80', => Top 0-10
    // '#FFEA80', => Top 10-100
    // '#ffbe80',
    // '#FF9280', => Top 100-1000
    // '#E5B0FF'  => Top 1000 >
    if(parseInt(rank) < 10){
      return '#ADFF80';
    }else if(parseInt(rank) >= 10 && parseInt(rank) < 100){
      return '#FFEA80';
    }else if(parseInt(rank) >= 100 && parseInt(rank) < 1000){
      return '#FF9280';
    }else if(parseInt(rank) > 1000){
      return '#E5B0FF';
    }
  };

  const GradientLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: '10px',
    borderRadius: '5px',
    background: 'linear-gradient(to right, #1a90ff, #e52e71)', // Adjust the gradient colors here
    '& .MuiLinearProgress-bar': {
      background: 'transparent', // Make the bar background transparent
    },
  }));

  useEffect(() => {
    setAuthLoading(false);
    props.authState.then(function(authData){
      if(authData){
        setAuth(true);
        console.log(`Auth Connection Status: true | ${JSON.stringify(authData.data.decodedJwtToken.decodedJwtToken.INTERVIEW_USERDATA_username)}`);
        let __createdtime__ = Date.now();
        var jwtUserDetails = atob(window.localStorage.getItem("Authorization").split(".")[1]);
        setLoggedUser(JSON.parse(jwtUserDetails).INTERVIEW_USERDATA_username);

        // page data init
        axios.get(`${process.env.REACT_APP_REST_API_URL}/interview/${params.INTERVIEW_METADATA_id}`, { 
          headers: { 'Content-Type': 'application/json', authtoken: window.localStorage.getItem("Authorization"), '__createdtime__': __createdtime__ },
          maxBodyLength: Infinity,
        })
        .then((res) => {
          if(res.data.error === true) {
            setIsError(res.data.errorMessage);
          }else{
            if(res.data){
              setIsError('');

              let publishedDate = res.data.Item.INTERVIEW_METADATA_published_date ? res.data.Item.INTERVIEW_METADATA_published_date.S : null;
              if(publishedDate){
                let ageSincePublished = getHoursDifference(Date.now(), publishedDate);
                if(ageSincePublished > 24){ // rating is only allowed 24 hours from publish date
                  setIsRatingEnabled(true);
                }
              }

              setInterviewMetadata({
                id: res.data.Item.INTERVIEW_METADATA_id.S,
                interviewee: JSON.parse(res.data.Item.INTERVIEW_METADATA_interviewee.S),
                interviewer: JSON.parse(res.data.Item.INTERVIEW_METADATA_interviewer.S),
                status: res.data.Item.INTERVIEW_METADATA_status.S,
                createdDate: res.data.Item.INTERVIEW_METADATA_created_date.S,
                inviteLink: res.data.Item.INTERVIEW_METADATA_invite_link.S,
                published_date: publishedDate,
                rating: res.data.Item.INTERVIEW_METADATA_rating ? res.data.Item.INTERVIEW_METADATA_rating.S : "0",
                events: res.data.Item.INTERVIEW_METADATA_events ? res.data.Item.INTERVIEW_METADATA_events.S : false
              });

              if(res.data.Item.INTERVIEW_METADATA_response_json.S !== '')
                setInterviewData(JSON.parse(res.data.Item.INTERVIEW_METADATA_response_json.S));

              const inviteExpirationTmp = JSON.parse(atob(res.data.Item.INTERVIEW_METADATA_invite_link.S.split("/")[2].split(".")[1])).exp;
              const inviteExpiration = new Date(parseInt(inviteExpirationTmp * 1000)).toLocaleString("en-US");
              const isExpired        = (inviteExpirationTmp * 1000 - Date.now() > 0) ? false : true;
              const hrsLeft          = (inviteExpirationTmp * 1000 - Date.now()) / (1000 * 60 * 60);
              setIsExpiredInterview(isExpired);
              setHrsLeftInterview(hrsLeft);
              setIsExpirationInterview(inviteExpiration);

              const socketInit = socketIO.connect(process.env.REACT_APP_SOCKET_API_URL,
                { transports: ["websocket"], query: `token=${window.localStorage.getItem("Authorization")}`, extraHeaders: { Authorization: `Bearer ${window.localStorage.getItem("Authorization")}` } }
              );
              setSocket(socketInit);
            }else{
              setIsError('Unable to fetched interview details.');
            }
          }
          setInprogress(false);
        })
        .catch((e) => {
          setInprogress(false);
          console.log(e);
        });
      }else{
        setIsError("Unathorized!");
        setInprogress(false);
        console.log(`Auth Connection Status: false | Error`);
        window.localStorage.removeItem("Authorization");
        window.localStorage.removeItem("ssoProvider");
        removeCookie("Authorization");
        removeCookie("ssoProvider");
        window.location.href = '/auth';
      }
    }).catch(function(err){
      setIsError("Unathorized!");
      setInprogress(false);
      console.log(`Auth Connection Status: false | ${JSON.stringify(err)}`);
      window.localStorage.removeItem("Authorization");
      window.localStorage.removeItem("ssoProvider");
      removeCookie("Authorization");
      removeCookie("ssoProvider");
      window.location.href = '/auth';
    });
  }, []);

  useEffect(() => {
    if(socket !== null){
      setTimeout(function(){
        setIsSocketConnected(socket.connected);
      },1000);

      socket.on('unauthorized', (error, callback) => {
        if (error.data.type == 'UnauthorizedError' || error.data.code == 'invalid_token') {
          // redirect user to login page perhaps?
          callback();
          console.log('User token has expired');
        }
      });

      socket.emit('ping', (data) => {
        console.log(`Ping: ${JSON.stringify(data)}`);
      });

      socket.on('pong', (data) => {
        console.log(`Pong: ${JSON.stringify(data)}`);
      });

      socket.on('receive_analyzed_text', (data) => {
        // console.log(`receive_analyzed_text: ${JSON.stringify(data)}`);
        // data.message.bpe_strings
        // data.message.real_topk = [rank, ]
        setInprogressM(false);
        setInprogressMLabel(false);
        try {
          if (AIAnalysisContent.hasOwnProperty("analysis")){
            setAIAnalysisContent(data);
          }else{
            setAIAnalysisContent(false);
          }
        } catch (error) {
          console.log(error)
          setAIAnalysisContent(false);
        }
      });

      socket.on('reschedInterviewNotif', (data) => {
        if(data.rescheduled.id === interviewMetadata.id){
          const inviteExpirationTmp = JSON.parse(atob(data.rescheduled.inviteLinkNav.split("/")[2].split(".")[1])).exp;
          const inviteExpiration = new Date(parseInt(inviteExpirationTmp * 1000)).toLocaleString("en-US");
          const isExpired        = (inviteExpirationTmp * 1000 - Date.now() > 0) ? false : true;
          const hrsLeft          = (inviteExpirationTmp * 1000 - Date.now()) / (1000 * 60 * 60);
          setIsExpiredInterview(isExpired);
          setHrsLeftInterview(hrsLeft);
          setIsExpirationInterview(inviteExpiration);
          setInprogressN(false);
        }
      });
    }
  }, [socket]);

  const handleResetClick = (path) => {
    window.location.pathname === path ? window.location.reload() : navigate(path)
  };

  const handleRating = (id, ratingParam) => {
    let rating = ratingParam === null ? 0 : ratingParam;
    console.log(`Rating: [${id}] => ${rating}`);
    let ratingData = {'INTERVIEW_METADATA_id': id, 'INTERVIEW_METADATA_rating': rating};
    socket.emit('rating', ratingData);
    // Create a copy of the object
    const interviewMetadataTmp = { ...interviewMetadata };
    // Update the specific key
    interviewMetadataTmp.rating = rating
    // Set the new object in the state
    setInterviewMetadata(interviewMetadataTmp);
  };

  const getHoursDifference = (timestamp1, timestamp2) => {
    // Convert the timestamps to Date objects
    const date1 = timestamp1;
    const date2 = timestamp2;

    // Calculate the difference in milliseconds
    const diffInMs = Math.abs(date2 - date1);

    // Convert milliseconds to hours
    const diffInHours = diffInMs / (1000 * 60 * 60);

    return diffInHours;
  };

  const handleAICheckClick = (toogle, a) =>{
    if(toogle){
      setIsAICheckOpen(true);
      setInprogressM(true);
      setAIAnalysisContent(null);
      let textData = { 'generatedText': a, model: 'RADAR' };
      socket.emit('analyzeGeneratedText', textData);

      setTimeout(function(){
        setInprogressMLabel(true);
      }, 8000);
    }
  };

  const handleViewEventClick = (toogle) => {
    setIsEventViewOpen(toogle);
  };

  const handleReschedClick = () => {
    console.log(`Resched Date: ${reschedDate}`);
    let reschedData = {"INTERVIEW_METADATA_id": interviewMetadata.id, "reschedDate": reschedDate.valueOf()};
    socket.emit('reschedInterview', reschedData);
    setIsReschedViewOpen(false);
    setInprogressN(true);
  };

  return (
    <>
      <Grid container spacing={1} direction="column" alignItems="center" sx={{margin: "15px 0px 0px 0px"}}> 
        <Grid sx={{minWidth: isMobile ? "100%!important" : "80%!important", maxWidth: isMobile ? "100%!important" : "80%!important"}}>

          { isError === '' ?
            <></>
          :
            <FormControl fullWidth>
              <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, minHeight: "50px" }} noValidate autoComplete="off">
                <Alert sx={{ margin: "10px", width: isMobile ? '93%' : '98%' }} severity="error">
                  {isError} — <strong style={{textDecoration: "underline", cursor: "pointer"}} ><a onClick={() => handleResetClick(isError === "Unathorized!"? "/auth" : "/welcome")}>start again!</a></strong>
                </Alert>
              </Box>
            </FormControl>
          }

          { auth === true ? 
            <FormControl fullWidth sx={{margin: "0px 0px 0px 0px"}}>
              {/* Interviewee / Interviewee Details */}
              <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, padding: '0px 30px 0px 20px' }} noValidate autoComplete="off">
                <Typography variant="h4" gutterBottom> Interview Details</Typography> 
                { isInprogress ? 
                    <Box sx={{margin: '10px 0px 10px 0px', minWidth: "41vh"}}>
                      <Skeleton />
                      <Skeleton animation="wave" />
                      <Skeleton animation={false} />
                    </Box>
                  :
                    Object.keys(interviewMetadata).length <= 0 ?
                      <Alert sx={{margin: '10px 0px 10px 0px', minWidth: "41vh"}} severity="warning">
                        <div>No Content Available.</div>
                      </Alert>
                    :
                      <Card sx={{ minWidth: 275, margin: '10px 0px 15px 0px', backgroundColor: '#fbfbfb', borderRadius: '0px' }}>
                        <CardContent>
                          <Typography gutterBottom sx={{ color: 'text.secondary', fontSize: 14 }}>
                            {interviewMetadata.interviewee.designation}
                          </Typography>
                          <Typography variant="h5" component="div">
                            {interviewMetadata.interviewee.name} <br/> <div style={{fontSize: '13px', margin: '0px 0px 0px 0px'}}>{interviewMetadata.interviewee.email}</div>
                          </Typography>
                          <Typography sx={{ color: isExpiredInterview && interviewMetadata.status === 'PENDING' ? 'red' : !isExpiredInterview && interviewMetadata.status === 'PENDING' ? 'orange': 'green', fontWeight: '800', mb: 1.5 }}>{
                                                   isExpiredInterview && interviewMetadata.status === 'PENDING' ? 'EXPIRED' : interviewMetadata.status} {isInprogressN ? "Calculating Expirating Date..." : interviewMetadata.status === 'PUBLISHED' || isExpiredInterview && interviewMetadata.status === 'PENDING'  ? `` : ` - ${isExpirationInterview} - ${hrsLeftInterview.toFixed(1)} hrs left` }
                          </Typography>
                          <Typography variant="body2">
                            <em>Prepared by {interviewMetadata.interviewee.designation} - {interviewMetadata.interviewer.name}. </em>
                            <br/>
                            { !isExpiredInterview && interviewMetadata.status === 'PENDING' ?
                              <Typography variant="subtitle2"> 
                               Created - {new Date(parseInt(interviewMetadata.createdDate)).toLocaleString("en-US")}
                              </Typography> 
                            :<></>}
                            <br/>
                            { Object.keys(interviewData).length > 0 ?
                              <>
                                <em>contains {interviewData.length} questions.</em>
                                <br/>
                                <Tooltip title="Rating only allowed 24hrs after interview completion.">
                                  <Rating sx={{margin: '15px 0px 0px -5px!important'}} name="simple-controlled"
                                    value={interviewMetadata.rating}
                                    disabled={isRatingEnabled ? true : false}
                                    onChange={(event, rating) => {
                                      handleRating(params.INTERVIEW_METADATA_id, rating)
                                    }}
                                  />
                                </Tooltip>
                              </>
                            : <></>}

                            <Button onClick={() => handleViewEventClick(isEventViewOpen ? false : true)} sx={{ margin: '10px 0px 10px 0px', float: 'right' }} startIcon={<ListAltIcon/>}>EVENTS</Button>
                            <Modal open={isEventViewOpen} onClose={(event, reason) => {
                              // Prevent closing the modal if the reason is 'backdropClick'
                              if (reason !== 'backdropClick') {
                                handleViewEventClick(false);
                              }
                              }} BackdropProps={{
                                  // Disable clicks on the backdrop to prevent closing the modal
                                  onClick: (event) => event.stopPropagation(),
                              }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                              <Box sx={{ width: '30%', minWidth: '290px', maxWidth: '350px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',bgcolor: 'background.paper', boxShadow: 24, p: 4, padding: '40px 40px 30px 40px!important' }}>
                                <Typography variant="h6" color="black" align="center"> 
                                  Event History
                                </Typography>
                                  {interviewMetadata.events !== false ? 
                                    <>
                                      <Divider sx={{color: 'gray', fontSize: '13px', margin: '10px 0px 15px 0px'}}> {interviewMetadata.events.split(",").filter(item => item !== "").length} events</Divider>
                                      <Box sx={{ maxWidth: 400, margin: '10px 0px 0px 0px', justifyContent: 'center', display: 'flex', maxHeight: '500px', overflowY: 'scroll', overflowX: 'auto' }}>
                                        <Stepper orientation="vertical">
                                          {interviewMetadata.events.split(",").filter(item => item !== "").reverse().map((event, index) => (
                                            <Step key={event} active={true}>
                                              <StepLabel>
                                                {event.split("|")[0] ? event.split("|")[0].toUpperCase() : "error"} 
                                                <br/> 
                                                <small style={{fontSize: '9px'}}>{event.split("|")[2] ? event.split("|")[2].toUpperCase() : "error"}</small> 
                                                <br/> 
                                                <span style={{fontSize: '8px'}}>{new Date(parseInt(event.split("|")[1])).toLocaleString("en-US")}</span>
                                              </StepLabel>
                                            </Step>
                                          ))}
                                        </Stepper>
                                      </Box>
                                    </>
                                  :
                                    <Divider sx={{color: 'gray', fontSize: '13px', margin: '10px 0px 15px 0px'}}> no event history available </Divider>
                                  }
                                <Button onClick={() => handleViewEventClick(false)} sx={{ margin: '15px 0px 0px 0px', fontSize: '10px', float: 'right' }}  startIcon={<CloseIcon/>}>Close</Button>
                              </Box>
                            </Modal>
                            
                            { !isExpiredInterview && interviewMetadata.status === 'PENDING' ?
                              <>
                                <Button onClick={() => setIsReschedViewOpen(isReschedViewOpen ? false : true)} sx={{ margin: '10px 0px 10px 0px', float: 'right' }} startIcon={<CalendarMonthIcon/>}>Re-Sched</Button>
                                <Modal open={isReschedViewOpen} onClose={(event, reason) => {
                                  // Prevent closing the modal if the reason is 'backdropClick'
                                  if (reason !== 'backdropClick') {
                                    setIsReschedViewOpen(false);
                                  }
                                  }} BackdropProps={{
                                      // Disable clicks on the backdrop to prevent closing the modal
                                      onClick: (event) => event.stopPropagation(),
                                  }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                                  <Box sx={{ width: '30%', minWidth: '290px', maxWidth: '390px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',bgcolor: 'background.paper', boxShadow: 24, p: 4, padding: '40px 40px 30px 40px!important' }}>
                                    <Typography variant="h6" color="black" align="center"> 
                                      Re-Schedule Interview
                                    </Typography>

                                    <Box display="flex" alignItems="center">
                                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                          disabled
                                          size="small"
                                          sx={{width: '100%', margin: '20px 0px 0px 0px'}}
                                          label="Original Date"
                                          value={dayjs(isExpirationInterview)}
                                          renderInput={(params) => <TextField disabled size="small" {...params} fullWidth />}
                                        />
                                      </LocalizationProvider>
                                    </Box>

                                    {/* <hr style={{border: 'none', width: '2px', height: '100px', backgroundColor: 'gray',  margin: '0 auto' }}/>
                                     */}
                                    <Box display="flex" alignItems="center">
                                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                          size="small"
                                          sx={{width: '100%', margin: '20px 0px 0px 0px'}}
                                          label="Reschedule Date"
                                          value={reschedDate}
                                          onChange={(newValue) => {
                                            setReschedDate(newValue);
                                          }}
                                          renderInput={(params) => <TextField size="small" {...params} fullWidth />}
                                        />
                                      </LocalizationProvider>
                                    </Box>
                                    <div style={{fontSize: '9px', margin: '0px 0px 0px 0px'}}>Only non-expired interviews can be rescheduled.</div>
                                    <Button onClick={() => handleReschedClick()} sx={{ display: "flex", margin: '15px 0px 0px 0px', fontSize: '10px', float: 'right' }} endIcon={<ArrowRightAltIcon/>}>Proceed</Button>
                                    <Button onClick={() => setIsReschedViewOpen(false)} sx={{ margin: '15px 0px 0px 0px', fontSize: '10px', float: 'right' }}  startIcon={<CloseIcon/>}>Close</Button>
                                  </Box>
                                </Modal>
                              </>
                              : <></>
                            }
                              
                          </Typography>
                        </CardContent>
                      </Card>
                }
              </Box>
              {/* Interview Details */}
              <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' }, padding: '0px 30px 0px 20px' }} noValidate autoComplete="off">
                <Typography variant="button" sx={{color: '#808080'}} gutterBottom> ResponseID # {params.INTERVIEW_METADATA_id.slice(-40)}</Typography> 
                  { isInprogress ? 
                    <Box sx={{margin: '10px 0px 10px 0px', minWidth: "41vh"}}>
                      <Skeleton />
                      <Skeleton animation="wave" />
                      <Skeleton animation={false} />
                    </Box>
                  :
                    <Box sx={{margin: '10px 0px 10px 0px', minWidth: "41vh"}}>
                      {
                        Object.keys(interviewData).length <= 0 ?
                          isExpiredInterview ?
                            <Alert sx={{margin: '10px 0px 10px 0px', minWidth: "41vh"}} severity="error">
                              <div>Interview already expired.</div>
                            </Alert>
                          :
                            <>
                              <Alert sx={{margin: '10px 0px 10px 0px', minWidth: "41vh"}} severity="warning">
                                <div>Interview is not yet completed.</div>
                              </Alert>
                            </>
                      :
                        interviewData.map(function(perQa, index){
                          const q = perQa.q;
                          const a = perQa.a;
                          const type       = perQa.type;
                          const ansTimetmp = new Date(parseInt(perQa.__createdtime__));
                          const ansTime    = ansTimetmp.toLocaleString("en-US");
                          return(
                            <div style={{ minWidth: 275, margin: '18px 0px 15px 0px' }}>
                              <Accordion sx={{margin: '10px 0px 15px 0px'}}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{color: 'white'}} />} aria-controls={ "panel" + index + 1 + "-content" } id={ "panel" + index + 1 + "-header" } sx={{fontWeight: '420', backgroundColor: '#222222', color: 'white' }}>
                                  <div style={{width: '90%', fontSize: '13px'}}>{index + 1}. {q}</div>
                                </AccordionSummary>
                                <AccordionDetails sx={{ fontSize: '13px', padding: '15px 16px 15px', whiteSpace: 'break-spaces' }}>
                                  {type.toLowerCase() === 'text' ? 
                                    <>
                                      {a}
                                      <Button onClick={() => {handleAICheckClick(true, a)}} sx={{ display: 'flex', margin: '10px 0px -20px 0px', fontSize: '10px', padding: '5px 10px 5px 10px' }}  startIcon={<InsightsIcon/>}>AI Check</Button>
                                      <Modal open={isAICheckOpen} onClose={(event, reason) => {
                                          // Prevent closing the modal if the reason is 'backdropClick'
                                          if (reason !== 'backdropClick') {
                                            setIsAICheckOpen(false);
                                          }
                                          }} BackdropProps={{
                                              // Disable clicks on the backdrop to prevent closing the modal
                                              onClick: (event) => event.stopPropagation(),
                                          }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                                          <Box sx={{ width: isMobile? '90%' : '60%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
                                          <AccordionDetails sx={{ fontSize: '17px', padding: '15px 16px 15px'}}>
                                            Evaluating the likelihood of an answer being AI-generated <Tooltip title="Forked from: gltr.io & radar.vizhub.ai (A collaboration of MIT-IBM Watson AI lab and HarvardNLP) - OpenSource"><HelpOutlineIcon sx={{fontSize: '14px', margin: '0px 0px 0px 0px'}}/></Tooltip>
                                            {/* &nbsp;<span style={{backgroundColor: '#ADFF80', fontSize: '10px'}}>highly likely</span>&nbsp;
                                            <span style={{backgroundColor: '#FFEA80', fontSize: '10px'}}>moderate</span>&nbsp;
                                            <span style={{backgroundColor: '#FF9280', fontSize: '10px'}}>low</span>&nbsp;
                                            <span style={{backgroundColor: '#E5B0FF', fontSize: '10px'}}>unlikely</span> */}
                                          </AccordionDetails>
                                          {isInprogressM ? 
                                              <>
                                                {isInprogressMLabel ?
                                                  <div style={{textAlign: 'center', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', margin: '8px 0px 0px 0px', fontSize: '12px', color: 'rebeccapurple', fontWeight: '800'}}>Taking too long to analyze - please wait...</div>
                                                :<></>}
                                                <Box sx={{margin: '0 auto', width: '95%'}}>
                                                  <Skeleton />
                                                  <Skeleton animation="wave" />
                                                  <Skeleton animation={false} />
                                                </Box>
                                              </>
                                            :
                                            AIAnalysisContent !== null ?
                                              AIAnalysisContent === false ?
                                                <Typography variant="caption" gutterBottom sx={{ display: 'block', margin: '10px 0px 10px 0px', textAlign: 'center', color: 'red', fontSize: '24px' }}>
                                                  Something went wrong.
                                                </Typography>
                                              :
                                                <>
                                                  <AccordionDetails sx={{ fontSize: '13px', padding: '15px 16px 15px', wordWrap: 'break-word'}}>
                                                    {AIAnalysisContent.rawText}
                                                  </AccordionDetails>
                                                  <AccordionDetails sx={{ fontSize: '13px', padding: '15px 16px 15px', wordWrap: 'break-word'}}>
                                                    <GradientLinearProgress variant="determinate" value={JSON.parse(AIAnalysisContent.analysis).RADARVicuna7LM_Analysis * 100} />
                                                    <Typography variant="caption" gutterBottom sx={{ display: 'inline-flex' }}>
                                                      Human-written: {Math.abs(Math.round((JSON.parse(AIAnalysisContent.analysis).RADARVicuna7LM_Analysis * 100) - 100))}%
                                                    </Typography>
                                                    <Typography variant="caption" gutterBottom sx={{ display: 'inline-flex', float: 'right' }}>
                                                      AI-generated: {Math.abs(Math.round(JSON.parse(AIAnalysisContent.analysis).RADARVicuna7LM_Analysis * 100))}%
                                                    </Typography>
                                                    <Typography variant="caption" gutterBottom sx={{ display: 'block', margin: '-8px 0px 0px 0px', fontSize: '9px', textDecoration: 'underline' }}>
                                                      <Tooltip title="Forked from: gltr.io & radar.vizhub.ai (A collaboration of MIT-IBM Watson AI lab and HarvardNLP) - OpenSource">AI-written text detector based on the roberta-large model (fine-tuned with Vicuna-7B).</Tooltip>
                                                    </Typography>
                                                  </AccordionDetails>
                                                </>
                                            : <></>
                                          }
                                            <Button onClick={() => {setIsAICheckOpen(false)}} sx={{ margin: '5px 0px 0px 0px', float: 'right' }}  startIcon={<CloseIcon sx={{color: 'red'}} />}>Close</Button>
                                          </Box>
                                      </Modal>
                                      <div style={{margin: '10px -5px 5px 0px', fontSize: '10px', float: 'right'}}>{ansTime}</div>
                                    </>
                                  : 
                                    <>
                                      <Typography variant="caption" gutterBottom sx={{ color: "green", fontWeight: '800', width: '85%!important', maxWidth: '100%', margin: '9px 24px 5px 0px', display: "inline-block"}} >
                                        {isMobile ? "" : "VIDEO RECORDING IS AVAILABLE"}
                                      </Typography>
                                      <Modal open={videoPlayerModalIndex === index} onClose={(event, reason) => {
                                          // Prevent closing the modal if the reason is 'backdropClick'
                                          if (reason !== 'backdropClick') {
                                              setVideoPlayerModalIndex(null);
                                          }
                                          }} BackdropProps={{
                                              // Disable clicks on the backdrop to prevent closing the modal
                                              onClick: (event) => event.stopPropagation(),
                                          }} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                                          <Box sx={{ width: '60%', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
                                            <video style={{width: '100%', height: '100%'}} src={a} controls></video>
                                            <Button onClick={() => {setVideoPlayerModalIndex(null)}} sx={{ margin: '5px 0px 0px 0px', float: 'right' }}  startIcon={<CloseIcon sx={{color: 'red'}} />}>Close</Button>
                                            <a style={{float: 'left', margin: '5px 0px 0px 0px', fontSize: '10px' }} download href={a}> Download Recording </a>
                                          </Box>
                                      </Modal>
                                      <div style={{display: 'inline-flex', float: 'right', margin: '-35px 0px 0px 0px'}}>
                                        <Button onClick={() => {setVideoPlayerModalIndex(index)}} sx={{ margin: '6px 0px 0px 0px', float: 'right', fontSize: '12px' }}  startIcon={<PlayCircleIcon sx={{color: 'red'}} />}>View Recording</Button>
                                        <div style={{margin: '15px -5px 5px 0px', fontSize: '10px', float: 'right'}}>{ansTime}</div>
                                      </div>
                                    </>
                                  }
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          )
                        })
                      }
                      <Button onClick={() => {navigate('/welcome')}} sx={{ margin: '5px 0px 0px 0px', float: 'right' }} color="secondary">Go Back</Button>
                    </Box>
                  }
                  
                  {/* { 
                    isMobile ?
                      <></>
                    : 
                      <></>
                  } */}
              </Box>
            </FormControl>
          : 
            <></>
          }
          </Grid>
      </Grid>
    </>
  )
}

export default InterviewPage;